import { useEffect } from 'react';
import { navigate } from 'gatsby';

const IndexPage = () => {
  useEffect(() => {
    // Client side routing fallback
    navigate('/insiders-guide');
  }, []);

  return null;
};

export default IndexPage;
